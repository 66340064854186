import request from '@/until/request.js'
const prefixName = '/base/users/'

// 获取验证码
export function getCode(data){
	return request({
	    url: 'gm/api/mobile',
	    method: 'get',
		  params:data
	  })
}
/**
 * 验证手机号码是否正确
 * @param {Object} data
 */
export function checkCode(code){
	return request({
		url:'base/user-custom/checkCode',
		method:'put',
		data:{code}
	});
}
/**
 * 变更为新手机号码
 * @param {Object} tokenId
 * @param {Object} phone
 * @param {Object} code
 */
export function resetPhone(tokenId,phone,code){
	return request({
		url:'base/user-custom/resetPhone',
		method:'put',
		data:{tokenId,phone,code}
	});
}
// 修改密码
export function modifypwd(result) {
    return request({
      url: '/modifypwd',
      method: 'post',
      data: result,
      headers:{
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
  }
// 获取个人信息
export function GetInformation(id){
    return request({
        url: '/base/users/' + id, 
        method: 'get'
    })
}
// 获取公司信息
export function GetCompanyBasic(){
  return request({
      url: '/gm/system/company', 
      method: 'get'
  })
}
export function EditCompanyBasic(result){
  return request({
    url: '/gm/system/company',
    method: 'put',
    data: result
  })
}
// 获取参数配置
export function GetParameter(){
  return request({
    url: '/gm/system/config', 
    method: 'get'
})
}
// 修改参数配置
export function EditParameter(result){
  return request({
    url: '/gm/system/config',
    method: 'put',
    data: result
  })
}
// 获取期初应收列表
export function getCus(query){
  return request({
    url: '/gm/webInitial/cus', 
    method: 'get',
    params:query
})
}
// 获取期初应付列表
export function getSup(query){
  return request({
    url: '/gm/webInitial/sup', 
    method: 'get',
    params:query
})
}
// 设置期初应收
export function setCus(data){
  return request({
    url: '/gm/webInitial/cus', 
    method: 'post',
    data:data
})
}
// 设置期初应付
export function setSup(data){
  return request({
    url: '/gm/webInitial/sup', 
    method: 'post',
    data:data
})
}
// 获取期初账户列表
export function getAut(query){
  return request({
    url: '/cw/webInitial/aut', 
    method: 'get',
    params:query
})
}
// 设置期初应付
export function setAut(data){
  return request({
    url: '/cw/webInitial/aut', 
    method: 'post',
    data:data
})
}
export function getValidateCode() {
  return request({
    url: '/captcha',
    method: 'get'
  })
}
export function getAreasCascade() {
  return request({
    url: '/base/areas/cascade',
    method: 'get'
  })
}