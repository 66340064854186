<template>
    <div class="Wrap">
        <div class="passwordTip">
            <p class="first">如何提高密码的安全性？</p>
            <p>密码长度必须为8-16位，且同时包含字母、数字</p>
            <p>避免使用过于简单的密码，例如123456、password</p>
            <p>避免使用人名或生日，例如zhangsan、zhangsan1990</p>
            <p>避免使用手机号码，例如135 6677 8899</p>
        </div>
        <SimpleForm
            ref="form"
            :form="changePwdForm"
            :rules="Pwdrules"
            :formFields="changePwdFields"
            :isShowSave="false"
            :isShowCancel="false"
        />
         <div class="footer">
            <el-button type="primary" @click="submit('changePwdForm')">提 交</el-button>
            <el-button @click="close">取 消</el-button>
        </div>
    </div>
</template>
<script>
import { modifypwd } from '@/api/system.js'
import md5 from 'md5'
export default {
    data(){
        const validatePwd = (rule, value, callback) => {
            const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
            if (value !== '') {
                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error('请设置8-16位数字、字母组合的密码'))
                }
            } else {
                callback(new Error('请填写新密码'))
            }
        }
        const validateAffirmPwd = (rule, value, callback) => {
            if (this.changePwdForm.newPassword && this.changePwdForm.newPassword !== value) {
                callback(new Error('确认密码与新密码不一致'))
            } else {
                this.$refs['form'].$children[0].clearValidate('newPassword')
                callback()
            }
        }
        return{
            changePwdForm:{
                oldPassword: '',
                newPassword: '',
                repeatPassword:'',
            },
            changePwdFields:[
                {name:'原密码',field: 'oldPassword', type: 'pwd'},
                {name:'新密码',field: 'newPassword', type: 'pwd'},
                {name:'确认密码',field: 'repeatPassword', type: 'pwd'}
            ],
            Pwdrules: {
                oldPassword: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                ], 
                newPassword: [
                    { required: true, message: '请填写新密码', trigger: 'blur' },
                    { required: true, trigger: 'blur', validator: validatePwd }
                ], 
                repeatPassword: [
                    { required: true, message: '请填写确认密码', trigger: 'blur' },
                    { required: true, trigger: 'blur', validator: validateAffirmPwd }
                ]
            }
        }
    },
    mounted(){
        this.$refs['form'].$children[0].resetFields()
    },
    methods:{
        submit(formName){
            this.$refs['form'].$children[0].validate((valid) =>{
                if(valid){
                    const form = {
                        password: md5(this.changePwdForm.oldPassword.toString()),
                        newPassword: md5(this.changePwdForm.newPassword.toString())
                    }
                    modifypwd(form).then(res => {
                        if(res.code === 1) {
                            this.$message.success('修改成功，请重新登录')
                            setTimeout(()=> {
                                sessionStorage.clear()
                                this.$router.push({path: '/Login'})
                                location.reload()
                            },2000)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.Wrap{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    .passwordTip{
        border: 1px solid #CEDBEF;
        padding: 10px 20px;
        margin-bottom: 20px;
        p{
            font-size: 14px;
        }
        .first{
            color: #187AE8;
            font-size: 16px;
            margin-bottom: 5px;
        }
    }
    .footer{
        text-align: center;
    }
}
</style>